<template>
  <div class="flex-col items-center">
    <h1 class="text-gray-700 text-6xl uppercase">error</h1>
    <router-link class="mt-5 text-xl text-green-600 hover:underline" to="/"
      >Volver a la página de Inicio</router-link
    >
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>
