<template>
  <div class="flex-col">
    <div class="flex justify-center">
      <easy-spinner
        v-if="isLoading"
        :color="'#68d391'"
        :size="100"
      ></easy-spinner>
    </div>

    <template v-if="!isLoading">
      <div class="flex flex-col sm:flex-row justify-around items-center">
        <div class="flex flex-col items-center">
          <img
            :src="`https://static.coincap.io/assets/icons/${asset.symbol.toLowerCase()}@2x.png`"
            :alt="asset.name"
            class="w-20 h-20 mr-5"
          />
          <h1 class="text-5xl">
            {{ asset.name }}
            <small class="sm:mr-2 text-gray-500">{{ asset.symbol }}</small>
          </h1>
        </div>

        <div class="my-10 flex flex-col">
          <ul>
            <li class="flex justify-between">
              <b class="text-gray-600 mr-10 uppercase">Ranking</b>
              <span>#{{ asset.rank }}</span>
            </li>
            <li class="flex justify-between">
              <b class="text-gray-600 mr-10 uppercase">Precio actual</b>
              <span>{{ $filters.dollarFilter(asset.priceUsd) }}</span>
            </li>
            <li class="flex justify-between">
              <b class="text-gray-600 mr-10 uppercase">Precio más bajo</b>
              <span>{{ $filters.dollarFilter(min) }}</span>
            </li>
            <li class="flex justify-between">
              <b class="text-gray-600 mr-10 uppercase">Precio más alto</b>
              <span>{{ $filters.dollarFilter(max) }}</span>
            </li>
            <li class="flex justify-between">
              <b class="text-gray-600 mr-10 uppercase">Precio Promedio</b>
              <span>{{ $filters.dollarFilter(avg) }}</span>
            </li>
            <li class="flex justify-between">
              <b class="text-gray-600 mr-10 uppercase">Variación 24hs</b>
              <span>{{ $filters.percentFilter(asset.changePercent24Hr) }}</span>
            </li>
          </ul>
        </div>

        <div class="my-10 sm:mt-0 flex flex-col justify-center text-center">
          <button
          @click="toggleConverter"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            {{ fromUsd ? `USD a ${asset.symbol}` : `${asset.symbol} a USD` }}
          </button>

          <div class="flex flex-row my-5">
            <label class="w-full" for="convertValue">
              <input
                v-model="convertValue"
                id="convertValue"
                type="number"
                :placeholder="`Valor en ${fromUsd ? 'USD' : asset.symbol}`"
                class="text-center bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
              />
            </label>
          </div>

          <span class="text-xl">{{ convertResult }}  {{ !fromUsd ? `USD` : `${asset.symbol}` }}</span>
        </div>
      </div>
      <div class="flex flex-col justify-center text-center">
        <line-chart
          class="my-10"
          loading="true"
          :colors="['orange']"
          :min="min"
          :max="max"
          :data="dataChart"
        ></line-chart>
      </div>
      <div class="flex flex-col justify-center text-center">
        <h3 class="text-xl my-10">Mejores Ofertas de Cambio</h3>
        <table>
          <tr v-for="(market, index) in markets" :key="index" class="border-b">
            <td>
              <b>{{ market.exchangeId }}</b>
            </td>
            <td>{{ $filters.dollarFilter(market.priceUsd) }}</td>
            <td>{{ market.baseSymbol }} / {{ market.quoteSymbol }}</td>
            S
            <td>
              <PxButton
                :is-loading="market.isLoading || false"
                v-if="!market.url"
                @custom-click="getWebsite(market)"
              >
                <p>Obtener Link</p>
              </PxButton>
              <a v-else class="hover:underline text-green-600" target="_blanck">
                {{ market.url }}
              </a>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/api";
import PxButton from "@/components/PxButton";
export default {
  name: "CoinDetail",
  components: { PxButton },
  data() {
    return {
      asset: {},
      history: [],
      isLoading: false,
      markets: [],
      fromUsd: true,
      convertValue:null
    };
  },

  computed: {
    convertResult(){
      if(!this.convertValue){return 0}
      const result = this.fromUsd ? this.convertValue / this.asset.priceUsd : this.convertValue * this.asset.priceUsd; 
      return result.toFixed(2)
    },

    min() {
      return Math.min(
        ...this.history.map((h) => parseFloat(h.priceUsd).toFixed(2))
      );
    },

    max() {
      return Math.max(
        ...this.history.map((h) => parseFloat(h.priceUsd).toFixed(2))
      );
    },

    avg() {
      return Math.abs(
        ...this.history.map((h) => parseFloat(h.priceUsd).toFixed(2))
      );
    },

    dataChart() {
      const data = this.history.map((h) => [
        h.date,
        parseFloat(h.priceUsd).toFixed(2),
      ]);
      return data;
    },
  },

  watch:{
    $route(){
      this.getCoin()
    }
  },

  created() {
    this.getCoin();
  },

  methods: {
    toggleConverter(){
      this.fromUsd = !this.fromUsd
    },
    getWebsite(exchange) {
      exchange["isLoading"] = true;
      return api
        .getExchange(exchange.exchangeId)
        .then((res) => {
          exchange.url = res.exchangeUrl;

          //this.$set(exchange,"url",res.exchangeUrl)
        })
        .finally(() => (exchange["isLoading"] = true));
    },
    getCoin() {
      const id = this.$route.params.id;
      this.isLoading = true;
      Promise.all([
        api.getAsset(id),
        api.getAssetHistory(id),
        api.getMarkets(id),
      ])
        .then(([asset, history, markets]) => {
          this.asset = asset;
          this.history = history;
          this.markets = markets;
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped>
td {
  padding: 10px;
  text-align: center;
}
</style>
